import React, { useState } from "react"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { FBIcon, IGIcon } from "../Icons"

import "./footer.scss"

const Footer = () => {
  const [email, setEmail] = useState("")
  const [formResult, setFormResult] = useState(null)

  const handleFormSubmit = e => {
    e.preventDefault()
    setFormResult(null)

    if (!email.length) {
      setFormResult({ result: "error", msg: "Email is required" })
      return
    }

    addToMailchimp(email)
      .then(data => {
        setFormResult(data)

        if (data.result === "success") {
          setEmail("")
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-nav">
            <ul className="footer-links">
              <li>
                <Link className="link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="link" to="/about/">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="link" to="/product/no-shows/">
                  Shop
                </Link>
              </li>
              <li>
                <Link className="link" to="/returns/">
                  Returns
                </Link>
              </li>
              <li>
                <Link className="link" to="/contact/">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link className="link" to="/terms-of-service/">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link className="link" to="/privacy-policy/">
                  Privacy Policy
                </Link>
              </li>
            </ul>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/spectersocks/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FBIcon />
              </a>
              <a
                href="https://www.instagram.com/spectersocks/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IGIcon />
              </a>
            </div>
          </div>
          <div id="signup" className="email-grab">
            <p className="email-grab__subtitle">Let's stay in touch</p>
            <form
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              onSubmit={handleFormSubmit}
            >
              <div className="email-form">
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email address"
                  name="EMAIL"
                  className="required email input email-input"
                  id="mce-EMAIL"
                  required
                />
                <input
                  type="submit"
                  value="Sign Up"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button btn btn--white"
                />
                {formResult && (
                  <p
                    className="form-result"
                    dangerouslySetInnerHTML={{ __html: formResult.msg }}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="copyright">
          <p>© 2020 Specter Socks</p>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
