import { Link } from "gatsby"
import React from "react"

import "./navbar.scss"
import logoText from "../../images/logo-text.png"

const NavBar = () => (
  <nav className="container nav">
    <Link to="/">
      <img className="logo" src={logoText} alt="Specter" />
    </Link>
    <ul className="nav-list">
      <li className="nav-list-item">
        <Link activeClassName="active" to="/product/no-shows/">
          No-Shows
        </Link>
      </li>
      <li className="nav-list-item">
        <Link activeClassName="active" to="/about/">
          About
        </Link>
      </li>
      <li className="nav-list-item">
        <Link activeClassName="active" to="/contact/">
          Contact
        </Link>
      </li>
    </ul>
  </nav>
)

export { NavBar }
