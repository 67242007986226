import React from "react"
import PropTypes from "prop-types"

import { NavBar } from "../NavBar"
import { Main } from "../Main"
import { Footer } from "../Footer"

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
